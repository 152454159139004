import React from 'react';

import BANNERIMAGE from "./png/rel.png";
import RELEASES1 from "./png/li1.png";
import RELEASES2 from "./png/li2.png";
import RELEASES3 from "./png/li3.png";
import RELEASES4 from "./png/li4.png";
import RELEASES5 from "./png/li5.png";
import RELEASES6 from "./png/li6.png";
import RELEASES7 from "./png/li7.png";
import RELEASES8 from "./png/li8.png";
import BANNERARROW from "./svg/banner-arrows.svg";
import TEAMSBANNER from "./png/2.png";
import TEAMMEMBER from "./png/team.png";
import SERVICESBANNER from "./png/our-services-banner.png";
import SERVICEIMG from "./png/service-img.png";
import SERVICEIMG2 from "./png/service-img2.png";
import SERVICEIMG3 from "./png/service-img3.png";
import SERVICEIMG4 from "./png/service-img4.png";
import SERVICEIMG5 from "./png/service-img5.png";
import SLIDERIMAGE1 from "./png/images-slides/rel.png";
import SLIDERIMAGE2 from "./png/images-slides/rel1.png";
import SLIDERIMAGE3 from "./png/images-slides/rel2.png";
import SERVICELOGO1 from "./png/services-logo/razor-logo.png";
import SERVICELOGO2 from "./png/services-logo/zr-logo.png";
import SERVICELOGO3 from "./png/services-logo/WOLF.png";
import SERVICELOGO4 from "./png/services-logo/toolroom-logo.png";
import SERVICELOGO5 from "./png/services-logo/faith.png";
import SERVICELOGO6 from "./png/services-logo/drumcode-logo.png";
import SERVICELOGO7 from "./png/services-logo/disc-logo.png";
import SERVICELOGO8 from "./png/services-logo/sb-logo.png";
import NOPRODUCTIMG from "./png/noImage.png";

export const BannerImage = BANNERIMAGE;
export const Releases1 = RELEASES1;
export const Releases2 = RELEASES2;
export const Releases3 = RELEASES3;
export const Releases4 = RELEASES4;
export const Releases5 = RELEASES5;
export const Releases6 = RELEASES6;
export const Releases7 = RELEASES7;
export const Releases8 = RELEASES8;
export const BannerArrow = BANNERARROW;
export const TemasBanner = TEAMSBANNER;
export const TeamMember = TEAMMEMBER;
export const ServicesBanner = SERVICESBANNER;
export const ServiceImg = SERVICEIMG;
export const ServiceImg2 = SERVICEIMG2;
export const ServiceImg3 = SERVICEIMG3;
export const ServiceImg4 = SERVICEIMG4;
export const ServiceImg5 = SERVICEIMG5;
export const SliderImage1 = SLIDERIMAGE1;
export const SliderImage2 = SLIDERIMAGE2;
export const SliderImage3 = SLIDERIMAGE3;
export const ServiceLogo1 = SERVICELOGO1;
export const ServiceLogo2 = SERVICELOGO2;
export const ServiceLogo3 = SERVICELOGO3;
export const ServiceLogo4 = SERVICELOGO4;
export const ServiceLogo5 = SERVICELOGO5;
export const ServiceLogo6 = SERVICELOGO6;
export const ServiceLogo7 = SERVICELOGO7;
export const ServiceLogo8 = SERVICELOGO8;
export const NoProductImage = NOPRODUCTIMG;


export const MainLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="368.826" height="368.731" viewBox="0 0 368.826 368.731">
        <g id="Group_9" data-name="Group 9" transform="translate(-6333.438 -15.293)">
            <g id="Group_2" data-name="Group 2">
                <g id="Group_1" data-name="Group 1">
                    <path id="Path_1" data-name="Path 1" d="M6517.986,384.024a184.241,184.241,0,1,1,119.586-44.068h0A183.617,183.617,0,0,1,6517.986,384.024Zm-.4-346.729a162.408,162.408,0,0,0-123.256,267.848c58.14,68.108,160.848,76.22,228.955,18.081a162.4,162.4,0,0,0-92.652-285.413Q6524.091,37.293,6517.59,37.295Z" fill="#fff" />
                </g>
            </g>
            <g id="Group_6" data-name="Group 6">
                <g id="_Radial_Repeat_" data-name="&lt;Radial Repeat&gt;">
                    <g id="Group_3" data-name="Group 3">
                        <path id="Path_2" data-name="Path 2" d="M6552.885,129.3c-8.475,14.678-25.687,23.793-45.95,12.094l-18.447-10.65-18.074,31.3-18.056-10.423,51.673-89.5,36.5,21.074C6560.8,94.895,6561.359,114.619,6552.885,129.3ZM6534.7,118.8c3.75-6.494,4.4-15.125-4.041-20l-17.8-10.274-15.223,26.369,17.537,10.125C6523.877,130.042,6531.025,125.163,6534.7,118.8Z" fill="#fff" />
                    </g>
                </g>
                <g id="_Radial_Repeat_2" data-name="&lt;Radial Repeat&gt;">
                    <g id="Group_4" data-name="Group 4">
                        <path id="Path_3" data-name="Path 3" d="M6561.312,265.251c-8.475-14.679-7.762-34.143,12.5-45.842l18.447-10.65-18.074-31.305,18.055-10.424,51.673,89.5-36.5,21.074C6587.148,289.3,6569.786,279.929,6561.312,265.251Zm18.185-10.5c3.75,6.495,10.9,11.375,19.341,6.5l17.8-10.274-15.225-26.37-17.537,10.125C6575.17,239.756,6575.822,248.386,6579.5,254.751Z" fill="#fff" />
                    </g>
                </g>
                <g id="_Radial_Repeat_3" data-name="&lt;Radial Repeat&gt;">
                    <g id="Group_5" data-name="Group 5">
                        <path id="Path_4" data-name="Path 4" d="M6439.359,204.571c16.95,0,33.449,10.35,33.449,33.748v21.3h36.148v20.849H6405.61V238.319C6405.61,214.921,6422.41,204.571,6439.359,204.571Zm0,21c-7.5,0-15.3,3.75-15.3,13.5v20.55h30.448v-20.25C6454.508,229.32,6446.709,225.57,6439.359,225.57Z" fill="#fff" />
                    </g>
                </g>
            </g>
            <g id="Group_8" data-name="Group 8" transform="translate(-1.674 -1.261)">
                <g id="Group_7" data-name="Group 7" transform="translate(6507.852 189.706)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="11.261" cy="11.261" r="11.261" fill="#fff" />
                </g>
            </g>
        </g>
    </svg>
)

export const SearchIcon = () => (
    <svg id="Group_15" data-name="Group 15" xmlns="http://www.w3.org/2000/svg" width="19.037" height="19.037" viewBox="0 0 19.037 19.037">
        <g id="Group_14" data-name="Group 14">
            <path id="Path_8" data-name="Path 8" d="M18.8,17.686l-5.414-5.414a7.55,7.55,0,1,0-1.122,1.122l5.414,5.414A.793.793,0,1,0,18.8,17.686Zm-11.269-4.2a5.949,5.949,0,1,1,5.949-5.949A5.955,5.955,0,0,1,7.535,13.487Z" transform="translate(0 -0.003)" fill="#fff" />
        </g>
    </svg>

)

export const UserIcon = () => (
    <svg id="Group_22" data-name="Group 22" xmlns="http://www.w3.org/2000/svg" width="20.369" height="21.858" viewBox="0 0 20.369 21.858">
        <g id="Group_19" data-name="Group 19" transform="translate(0 12.312)">
            <g id="Group_18" data-name="Group 18">
                <path id="Path_11" data-name="Path 11" d="M27.624,288.389c-6.567,0-10.184,3.107-10.184,8.748a.8.8,0,0,0,.8.8H37.011a.8.8,0,0,0,.8-.8C37.809,291.5,34.192,288.389,27.624,288.389Zm-8.56,7.951c.314-4.218,3.19-6.355,8.56-6.355s8.246,2.137,8.56,6.355Z" transform="translate(-17.44 -288.389)" fill="#fff" />
            </g>
        </g>
        <g id="Group_21" data-name="Group 21" transform="translate(4.893)">
            <g id="Group_20" data-name="Group 20">
                <path id="Path_12" data-name="Path 12" d="M137.341,0a5.228,5.228,0,0,0-5.292,5.4,5.309,5.309,0,1,0,10.583,0A5.228,5.228,0,0,0,137.341,0Zm0,9.546a3.946,3.946,0,0,1-3.7-4.148,3.627,3.627,0,0,1,3.7-3.8,3.668,3.668,0,0,1,3.7,3.8A3.946,3.946,0,0,1,137.341,9.546Z" transform="translate(-132.049)" fill="#fff" />
            </g>
        </g>
    </svg>
)

export const CupIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.858" height="19.149" viewBox="0 0 23.858 19.149">
        <g id="Group_323" data-name="Group 323" transform="translate(249.696 617.845) rotate(90)">
            <rect id="Rectangle_30" data-name="Rectangle 30" width="17.149" height="17.149" transform="translate(-616.845 231.547)" fill="none" stroke="#fff" strokeWidth="2" />
            <path id="Path_372" data-name="Path 372" d="M-615.788,231.546a7.333,7.333,0,0,1,6.847-4.708,7.331,7.331,0,0,1,6.847,4.708" transform="translate(0.67)" fill="none" stroke="#fff" strokeWidth="2" />
        </g>
    </svg>

)

export const SocialIcons = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="118.617" height="21.913" viewBox="0 0 118.617 21.913">
        <g id="Group_275" data-name="Group 275" transform="translate(-783.5 -4138.625)">
            <g id="Group_265" data-name="Group 265" transform="translate(784 4139.125)">
                <circle id="Ellipse_12" data-name="Ellipse 12" cx="10.456" cy="10.456" r="10.456" transform="translate(0 0)" fill="none" stroke="#000" strokeWidth="1" />
                <g id="Group_264" data-name="Group 264" transform="translate(4.268 4.268)">
                    <g id="Group_259" data-name="Group 259">
                        <g id="Group_258" data-name="Group 258">
                            <path id="Path_366" data-name="Path 366" d="M2365.887-3466.023h-4.641a3.868,3.868,0,0,0-3.868,3.868v4.641a3.868,3.868,0,0,0,3.868,3.867h4.641a3.868,3.868,0,0,0,3.868-3.867v-4.641A3.868,3.868,0,0,0,2365.887-3466.023Zm2.708,8.509a2.71,2.71,0,0,1-2.708,2.707h-4.641a2.71,2.71,0,0,1-2.708-2.707v-4.641a2.711,2.711,0,0,1,2.708-2.708h4.641a2.711,2.711,0,0,1,2.708,2.708Z" transform="translate(-2357.378 3466.023)" />
                        </g>
                    </g>
                    <g id="Group_261" data-name="Group 261" transform="translate(3.094 3.094)">
                        <g id="Group_260" data-name="Group 260">
                            <path id="Path_367" data-name="Path 367" d="M2420.517-3405.978a3.094,3.094,0,0,0-3.094,3.094,3.094,3.094,0,0,0,3.094,3.094,3.094,3.094,0,0,0,3.094-3.094A3.094,3.094,0,0,0,2420.517-3405.978Zm0,5.027a1.936,1.936,0,0,1-1.934-1.934,1.935,1.935,0,0,1,1.934-1.934,1.936,1.936,0,0,1,1.934,1.934A1.936,1.936,0,0,1,2420.517-3400.95Z" transform="translate(-2417.423 3405.978)" />
                        </g>
                    </g>
                    <g id="Group_263" data-name="Group 263" transform="translate(9.102 2.45)">
                        <g id="Group_262" data-name="Group 262">
                            <circle id="Ellipse_13" data-name="Ellipse 13" cx="0.412" cy="0.412" r="0.412" />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Group_268" data-name="Group 268" transform="translate(816.163 4139.125)">
                <circle id="Ellipse_14" data-name="Ellipse 14" cx="10.456" cy="10.456" r="10.456" transform="translate(0 0)" fill="none" stroke="#000" strokeWidth="1" />
                <g id="Group_267" data-name="Group 267" transform="translate(4.78 5.428)">
                    <g id="Group_266" data-name="Group 266">
                        <path id="Path_368" data-name="Path 368" d="M3003.86-3442.315a5.279,5.279,0,0,1-1.462.4,2.519,2.519,0,0,0,1.116-1.4,5.109,5.109,0,0,1-1.609.614,2.542,2.542,0,0,0-1.854-.8,2.536,2.536,0,0,0-2.536,2.537,2.6,2.6,0,0,0,.059.579,7.184,7.184,0,0,1-5.23-2.654,2.552,2.552,0,0,0-.347,1.282,2.54,2.54,0,0,0,1.127,2.108,2.5,2.5,0,0,1-1.146-.312v.028a2.549,2.549,0,0,0,2.033,2.493,2.522,2.522,0,0,1-.665.083,2.269,2.269,0,0,1-.48-.043,2.562,2.562,0,0,0,2.371,1.767,5.1,5.1,0,0,1-3.146,1.083,4.865,4.865,0,0,1-.607-.035,7.143,7.143,0,0,0,3.893,1.138,7.172,7.172,0,0,0,7.221-7.22c0-.112,0-.221-.009-.328A5.071,5.071,0,0,0,3003.86-3442.315Z" transform="translate(-2991.484 3443.505)" />
                    </g>
                </g>
            </g>
            <g id="Group_271" data-name="Group 271" transform="translate(848.327 4139.125)">
                <circle id="Ellipse_15" data-name="Ellipse 15" cx="10.456" cy="10.456" r="10.456" transform="translate(0 0)" fill="none" stroke="#000" strokeWidth="1" />
                <g id="Group_270" data-name="Group 270" transform="translate(3.962 5.991)">
                    <g id="Group_269" data-name="Group 269">
                        <path id="Path_369" data-name="Path 369" d="M3612.234-3431.721c-.353-.627-.735-.742-1.513-.786s-2.733-.075-4.428-.075-3.654.022-4.431.074-1.16.159-1.516.787a7.66,7.66,0,0,0-.55,3.6v.007a7.632,7.632,0,0,0,.55,3.6c.356.626.738.741,1.515.793s2.734.072,4.432.072,3.65-.026,4.429-.071,1.161-.166,1.513-.793a7.58,7.58,0,0,0,.551-3.595v-.006A7.6,7.6,0,0,0,3612.234-3431.721Zm-7.566,6.04v-4.871l4.06,2.435Z" transform="translate(-3599.797 3432.582)" />
                    </g>
                </g>
            </g>
            <g id="Group_274" data-name="Group 274" transform="translate(880.705 4139.125)">
                <circle id="Ellipse_16" data-name="Ellipse 16" cx="10.456" cy="10.456" r="10.456" transform="translate(0 0)" fill="none" stroke="#000" strokeWidth="1" />
                <g id="Group_273" data-name="Group 273" transform="translate(3.197 5.969)">
                    <g id="Group_272" data-name="Group 272">
                        <path id="Path_370" data-name="Path 370" d="M4161.672-3534.942a.654.654,0,0,1-.535.279.642.642,0,0,1-.375-.119c-2.45-1.723-6.527-1.818-9.28-1.177a.653.653,0,0,1-.784-.488.655.655,0,0,1,.489-.786c3.025-.7,7.549-.573,10.325,1.381A.654.654,0,0,1,4161.672-3534.942Zm1.275-2.549a.653.653,0,0,1-.53.271.65.65,0,0,1-.383-.124c-2.842-2.039-6.335-2.547-11-1.6a.657.657,0,0,1-.771-.51.653.653,0,0,1,.509-.771c5.041-1.029,8.859-.454,12.021,1.814A.657.657,0,0,1,4162.947-3537.492Zm1.288-3.425a.649.649,0,0,1-.54.286.655.655,0,0,1-.367-.112,11.748,11.748,0,0,0-3.475-1.531,16.1,16.1,0,0,0-9.153.329.656.656,0,0,1-.834-.4.654.654,0,0,1,.4-.834c3.858-1.363,9.835-1.334,13.8,1.354A.656.656,0,0,1,4164.235-3540.917Z" transform="translate(-4149.829 3544.067)" fill="#040505" />
                    </g>
                </g>
            </g>
        </g>
    </svg>

)


export const BannerArrows = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
        <g id="Group_398" data-name="Group 398" transform="translate(0 29) rotate(-90)">
            <g id="Ellipse_58" data-name="Ellipse 58" fill="none" stroke="#fff" strokeWidth="2">
                <circle cx="14.5" cy="14.5" r="14.5" stroke="none" />
                <circle cx="14.5" cy="14.5" r="13.5" fill="none" />
            </g>
            <g id="Group_28" data-name="Group 28" transform="translate(7.222 21.796) rotate(-90)">
                <path id="Path_13" data-name="Path 13" d="M7.163,14.326,0,7.163,7.163,0" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="2" />
                <line id="Line_7" data-name="Line 7" x2="14.413" transform="translate(0.461 7.163)" fill="none" stroke="#fff" strokeWidth="2" />
            </g>
        </g>
    </svg>

)

export const OurServices1 = () => (
    <svg id="Group_35" data-name="Group 35" xmlns="http://www.w3.org/2000/svg" width="82" height="83" viewBox="0 0 82 83">
        <g id="Ellipse_2" data-name="Ellipse 2" fill="none" stroke="#fff" strokeWidth="2">
            <ellipse cx="41" cy="41.5" rx="41" ry="41.5" stroke="none" />
            <ellipse cx="41" cy="41.5" rx="40" ry="40.5" fill="none" />
        </g>
        <g id="Group_34" data-name="Group 34" transform="translate(43.183 8.047) rotate(45)">
            <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="1.221" cy="0.827" rx="1.221" ry="0.827" transform="translate(20.681 13.524)" fill="none" stroke="#fff" strokeWidth="2" />
            <g id="Group_33" data-name="Group 33" transform="translate(0)">
                <path id="Path_18" data-name="Path 18" d="M39.389,4.241C35.338,7.674,29.1,9.873,22.111,9.873S8.885,7.674,4.833,4.24A14.712,14.712,0,0,1,1.324,0,10.75,10.75,0,0,0,0,5.115,10.658,10.658,0,0,0,1.1,9.788a14.469,14.469,0,0,0,3.734,4.673c4.052,3.433,10.285,5.632,17.278,5.632s13.227-2.2,17.278-5.632a14.469,14.469,0,0,0,3.734-4.673,10.653,10.653,0,0,0,1.1-4.673A10.75,10.75,0,0,0,42.9,0,14.718,14.718,0,0,1,39.389,4.241Z" transform="translate(0 30.304)" fill="none" stroke="#fff" strokeWidth="2" />
                <path id="Path_19" data-name="Path 19" d="M42.9,0a14.718,14.718,0,0,1-3.509,4.241c-.356.3-.735.59-1.124.873A27.381,27.381,0,0,1,23.32,9.849c-.4.015-.8.024-1.209.024-.508,0-1.011-.015-1.51-.038A27.272,27.272,0,0,1,5.957,5.113c-.389-.282-.768-.571-1.124-.873A14.718,14.718,0,0,1,1.324,0,10.75,10.75,0,0,0,0,5.115,10.66,10.66,0,0,0,1.1,9.788c.071.146.149.289.226.432a14.714,14.714,0,0,0,3.509,4.24c4.052,3.433,10.285,5.632,17.278,5.632s13.227-2.2,17.278-5.632A14.72,14.72,0,0,0,42.9,10.221c.077-.143.155-.287.226-.432a10.653,10.653,0,0,0,1.1-4.673A10.75,10.75,0,0,0,42.9,0Z" transform="translate(0 20.083)" fill="none" stroke="#fff" strokeWidth="2" />
                <path id="Path_20" data-name="Path 20" d="M22.111,0C9.9,0,0,6.706,0,14.978a10.658,10.658,0,0,0,1.1,4.673c.071.146.149.289.226.432a14.718,14.718,0,0,0,3.509,4.241c.356.3.735.59,1.124.873A27.27,27.27,0,0,0,20.6,29.918c.5.023,1,.038,1.51.038.406,0,.808-.009,1.209-.024A27.381,27.381,0,0,0,38.265,25.2c.389-.282.768-.571,1.124-.873A14.718,14.718,0,0,0,42.9,20.083c.077-.143.155-.286.226-.432a10.653,10.653,0,0,0,1.1-4.673C44.223,6.706,34.323,0,22.111,0ZM21.9,19.3c-4.038,0-7.312-2.218-7.312-4.953a4.507,4.507,0,0,1,2.524-3.741A9.53,9.53,0,0,1,21.9,9.4a9.615,9.615,0,0,1,4.637,1.124,4.576,4.576,0,0,1,2.675,3.829C29.214,17.086,25.941,19.3,21.9,19.3Z" fill="none" stroke="#fff" strokeWidth="2" />
            </g>
        </g>
    </svg>
)

export const OurServices2 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="83.78" height="83.78" viewBox="0 0 83.78 83.78">
        <g id="Group_37" data-name="Group 37" transform="translate(1 1)">
            <g id="Group_36" data-name="Group 36">
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="20.241" cy="20.241" r="20.241" transform="translate(21.321 8.675)" fill="none" stroke="#fff" strokeWidth="2" />
                <circle id="Ellipse_8" data-name="Ellipse 8" cx="7.548" cy="7.548" r="7.548" transform="translate(34.014 21.369)" fill="none" stroke="#fff" strokeWidth="2" />
                <circle id="Ellipse_9" data-name="Ellipse 9" cx="0.672" cy="0.672" r="0.672" transform="translate(40.89 28.244)" fill="none" stroke="#fff" strokeWidth="2" />
                <path id="Path_21" data-name="Path 21" d="M536.089,1593.317l10.5-7.6a2.835,2.835,0,0,1,1.663-.539h17.171a2.836,2.836,0,0,1,2.836,2.836h0a2.836,2.836,0,0,1-2.836,2.836h2.946a2.835,2.835,0,0,0,1.865-.7l8.978-7.837a2.836,2.836,0,0,1,3.95.215l.419.455a2.836,2.836,0,0,1-.231,4.067l-13.23,11.433a2.836,2.836,0,0,1-1.822.69l-15.525.174a2.836,2.836,0,0,0-1.566.493l-7.746,5.282c-.471.321-5.11,4.132-8.044,1.776-.487-.391-1.713-1.276-3.022-2.324a50.752,50.752,0,0,1-3.685-3.533C526.708,1598.241,535.591,1593.677,536.089,1593.317Z" transform="translate(-517.901 -1532.454)" fill="none" stroke="#fff" strokeWidth="2" />
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="40.89" cy="40.89" r="40.89" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="2" />
            </g>
        </g>
    </svg>
)

export const OurServices3 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="83" viewBox="0 0 82 83">
        <g id="Group_38" data-name="Group 38" transform="translate(-0.229)">
            <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(0.229)" fill="none" stroke="#fff" strokeWidth="2">
                <ellipse cx="41" cy="41.5" rx="41" ry="41.5" stroke="none" />
                <ellipse cx="41" cy="41.5" rx="40" ry="40.5" fill="none" />
            </g>
            <path id="Path_17" data-name="Path 17" d="M1040.726,1544.343l18.953,10.943v-6.01h8.235v11.574h-5.46L1073.4,1579.8l10.943-18.953h-5.46v-11.574h8.235v6.01l18.953-10.943-18.953-10.942v4.909h-8.235V1508.9a19.843,19.843,0,0,0-10.966,0v29.409h-8.235V1533.4Z" transform="translate(-1031.845 -1507.032)" fill="none" stroke="#fff" strokeWidth="2" />
        </g>
    </svg>

)

export const RightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><rect fill="none" height="24" width="24" /><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" /></svg>
)

export const Gmail = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" /></svg>

)

export const PhysicalDistribution = () => (
    <svg id="Group_35" data-name="Group 35" xmlns="http://www.w3.org/2000/svg" width="82" height="83" viewBox="0 0 82 83">
        <g id="Ellipse_2" data-name="Ellipse 2" fill="none" stroke="#fff" strokeWidth="2">
            <ellipse cx="41" cy="41.5" rx="41" ry="41.5" stroke="none" />
            <ellipse cx="41" cy="41.5" rx="40" ry="40.5" fill="none" />
        </g>
        <g id="Group_34" data-name="Group 34" transform="translate(43.183 8.047) rotate(45)">
            <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="1.221" cy="0.827" rx="1.221" ry="0.827" transform="translate(20.681 13.524)" fill="none" stroke="#fff" strokeWidth="2" />
            <g id="Group_33" data-name="Group 33" transform="translate(0)">
                <path id="Path_18" data-name="Path 18" d="M39.389,4.241C35.338,7.674,29.1,9.873,22.111,9.873S8.885,7.674,4.833,4.24A14.712,14.712,0,0,1,1.324,0,10.75,10.75,0,0,0,0,5.115,10.658,10.658,0,0,0,1.1,9.788a14.469,14.469,0,0,0,3.734,4.673c4.052,3.433,10.285,5.632,17.278,5.632s13.227-2.2,17.278-5.632a14.469,14.469,0,0,0,3.734-4.673,10.653,10.653,0,0,0,1.1-4.673A10.75,10.75,0,0,0,42.9,0,14.718,14.718,0,0,1,39.389,4.241Z" transform="translate(0 30.304)" fill="none" stroke="#fff" strokeWidth="2" />
                <path id="Path_19" data-name="Path 19" d="M42.9,0a14.718,14.718,0,0,1-3.509,4.241c-.356.3-.735.59-1.124.873A27.381,27.381,0,0,1,23.32,9.849c-.4.015-.8.024-1.209.024-.508,0-1.011-.015-1.51-.038A27.272,27.272,0,0,1,5.957,5.113c-.389-.282-.768-.571-1.124-.873A14.718,14.718,0,0,1,1.324,0,10.75,10.75,0,0,0,0,5.115,10.66,10.66,0,0,0,1.1,9.788c.071.146.149.289.226.432a14.714,14.714,0,0,0,3.509,4.24c4.052,3.433,10.285,5.632,17.278,5.632s13.227-2.2,17.278-5.632A14.72,14.72,0,0,0,42.9,10.221c.077-.143.155-.287.226-.432a10.653,10.653,0,0,0,1.1-4.673A10.75,10.75,0,0,0,42.9,0Z" transform="translate(0 20.083)" fill="none" stroke="#fff" strokeWidth="2" />
                <path id="Path_20" data-name="Path 20" d="M22.111,0C9.9,0,0,6.706,0,14.978a10.658,10.658,0,0,0,1.1,4.673c.071.146.149.289.226.432a14.718,14.718,0,0,0,3.509,4.241c.356.3.735.59,1.124.873A27.27,27.27,0,0,0,20.6,29.918c.5.023,1,.038,1.51.038.406,0,.808-.009,1.209-.024A27.381,27.381,0,0,0,38.265,25.2c.389-.282.768-.571,1.124-.873A14.718,14.718,0,0,0,42.9,20.083c.077-.143.155-.286.226-.432a10.653,10.653,0,0,0,1.1-4.673C44.223,6.706,34.323,0,22.111,0ZM21.9,19.3c-4.038,0-7.312-2.218-7.312-4.953a4.507,4.507,0,0,1,2.524-3.741A9.53,9.53,0,0,1,21.9,9.4a9.615,9.615,0,0,1,4.637,1.124,4.576,4.576,0,0,1,2.675,3.829C29.214,17.086,25.941,19.3,21.9,19.3Z" fill="none" stroke="#fff" strokeWidth="2" />
            </g>
        </g>
    </svg>

)

export const DigitalDistribution = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="83" viewBox="0 0 82 83">
        <g id="Group_38" data-name="Group 38" transform="translate(-0.229)">
            <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(0.229)" fill="none" stroke="#fff" strokeWidth="2">
                <ellipse cx="41" cy="41.5" rx="41" ry="41.5" stroke="none" />
                <ellipse cx="41" cy="41.5" rx="40" ry="40.5" fill="none" />
            </g>
            <path id="Path_17" data-name="Path 17" d="M1040.726,1544.343l18.953,10.943v-6.01h8.235v11.574h-5.46L1073.4,1579.8l10.943-18.953h-5.46v-11.574h8.235v6.01l18.953-10.943-18.953-10.942v4.909h-8.235V1508.9a19.843,19.843,0,0,0-10.966,0v29.409h-8.235V1533.4Z" transform="translate(-1031.845 -1507.032)" fill="none" stroke="#fff" strokeWidth="2" />
        </g>
    </svg>

)

export const VinylManufacturing = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="83.78" height="83.78" viewBox="0 0 83.78 83.78">
        <g id="Group_37" data-name="Group 37" transform="translate(1 1)">
            <g id="Group_36" data-name="Group 36">
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="20.241" cy="20.241" r="20.241" transform="translate(21.321 8.675)" fill="none" stroke="#fff" strokeWidth="2" />
                <circle id="Ellipse_8" data-name="Ellipse 8" cx="7.548" cy="7.548" r="7.548" transform="translate(34.014 21.369)" fill="none" stroke="#fff" strokeWidth="2" />
                <circle id="Ellipse_9" data-name="Ellipse 9" cx="0.672" cy="0.672" r="0.672" transform="translate(40.89 28.244)" fill="none" stroke="#fff" strokeWidth="2" />
                <path id="Path_21" data-name="Path 21" d="M536.089,1593.317l10.5-7.6a2.835,2.835,0,0,1,1.663-.539h17.171a2.836,2.836,0,0,1,2.836,2.836h0a2.836,2.836,0,0,1-2.836,2.836h2.946a2.835,2.835,0,0,0,1.865-.7l8.978-7.837a2.836,2.836,0,0,1,3.95.215l.419.455a2.836,2.836,0,0,1-.231,4.067l-13.23,11.433a2.836,2.836,0,0,1-1.822.69l-15.525.174a2.836,2.836,0,0,0-1.566.493l-7.746,5.282c-.471.321-5.11,4.132-8.044,1.776-.487-.391-1.713-1.276-3.022-2.324a50.752,50.752,0,0,1-3.685-3.533C526.708,1598.241,535.591,1593.677,536.089,1593.317Z" transform="translate(-517.901 -1532.454)" fill="none" stroke="#fff" strokeWidth="2" />
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="40.89" cy="40.89" r="40.89" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="2" />
            </g>
        </g>
    </svg>

)

export const Marketing = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="83.78" height="83.78" viewBox="0 0 83.78 83.78">
        <g id="Group_406" data-name="Group 406" transform="translate(-151 -4132)">
            <g id="Group_351" data-name="Group 351" transform="translate(152 4133)">
                <g id="Group_36" data-name="Group 36">
                    <circle id="Ellipse_10" data-name="Ellipse 10" cx="40.89" cy="40.89" r="40.89" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="2" />
                </g>
            </g>
            <g id="Group_354" data-name="Group 354" transform="translate(1114.93 5168.987)">
                <path id="Path_380" data-name="Path 380" d="M-932.177-977.8l-.067-.007a2.445,2.445,0,0,0-.257-.019l-4.076-.03-4.98-.037a1.3,1.3,0,0,1-.271-.022,4.026,4.026,0,0,1-2.681-2.092,3.953,3.953,0,0,1-.37-1.984q-.007-3.37-.016-6.739c-.01-4-.021-8.14-.024-12.209,0-1.014.026-2.047.052-3.047.013-.5.026-1.012.036-1.518a3.106,3.106,0,0,1,.789-2,4.561,4.561,0,0,1,1.977-1.348,1.048,1.048,0,0,1,.343-.071c2.545-.017,5.147-.028,7.387-.036l15.8-.055h5.325s-19-6.479-25.748-8.79l.126-.384c.155-.472.315-.958.474-1.439l2.129.724c1.733.589,3.526,1.2,5.288,1.8l8.881,3.036q7.287,2.492,14.575,4.982a2.079,2.079,0,0,0,.633.107l.146,0c1.227.019,2.5.038,3.745.04a3.612,3.612,0,0,1,2.8,1.324,3.339,3.339,0,0,1,.94,2.288c0,.8.012,1.618.024,2.406q.006.385.011.771c0,.107.012.209.021.3,0,.031.006.061.009.092v16.742c0,.031-.006.061-.009.092-.009.094-.019.191-.021.3q-.014.831-.027,1.661-.015.943-.031,1.886a1.012,1.012,0,0,1-.027.207,3.838,3.838,0,0,1-1.421,2.206,3.327,3.327,0,0,1-2.127.79c-1.976,0-3.984.017-5.926.034l-1.792.015c-.314,0-.63.011-.937.02l-.418.011Zm29.376-2.023.046,0c.034,0,.068,0,.1,0a.687.687,0,0,0,.416-.125l.117-.085a1.651,1.651,0,0,0,.875-1.374c0-.129.008-.262.017-.4s.018-.3.018-.454v-.734c.009-6.432.019-13.083.021-19.624,0-.951-.032-1.876-.071-2.9a1.7,1.7,0,0,0-1.551-1.456l-12.443-.006-3.831,0c-1.668,0-3.393,0-5.089,0-1.15,0-2.185,0-3.166,0-2.453.005-4.947.016-7.359.026-2.127.009-4.327.019-6.49.024a1.647,1.647,0,0,0-1.309.626,1.848,1.848,0,0,0-.432,1.253c0,3.354.006,6.765.008,10.063,0,3.577,0,7.276.009,10.914,0,.94.04,1.864.083,2.808a1.685,1.685,0,0,0,1.555,1.446H-902.8Z" transform="translate(0 0)" fill="#fff" />
                <path id="Path_381" data-name="Path 381" d="M-506.123-653.917a9.685,9.685,0,0,1-7.075-3.024,9.307,9.307,0,0,1-2.63-6.685v-.05a9.29,9.29,0,0,1,2.5-6.551,9.463,9.463,0,0,1,2.877-2.163,9.607,9.607,0,0,1,3.828-.946c.151-.006.3-.009.454-.009a9.473,9.473,0,0,1,6.881,2.811,10.08,10.08,0,0,1,2.146,3.236,9.635,9.635,0,0,1,.737,3.7,9.708,9.708,0,0,1-2.871,6.829,9.585,9.585,0,0,1-6.819,2.856Zm-.038-1.99c.109.006.219.008.328.008a7.445,7.445,0,0,0,5.345-2.443,7.245,7.245,0,0,0,2.07-5.288,7.292,7.292,0,0,0-2.038-5.3,7.572,7.572,0,0,0-5.6-2.456c-.133,0-.268,0-.4.01l-.151,0a7.4,7.4,0,0,0-5.152,2.394,7.29,7.29,0,0,0-2.111,5.346,7.344,7.344,0,0,0,2.1,5.337,7.458,7.458,0,0,0,5.334,2.4C-506.346-655.9-506.253-655.9-506.16-655.908Z" transform="translate(-408.603 -329.762)" fill="#fff" />
                <path id="Path_382" data-name="Path 382" d="M-815.674-366.5c-1.465,0-2.956-.015-4.4-.029l-1.185-.011a3.605,3.605,0,0,1-.523-.049.752.752,0,0,1-.526-.364,1,1,0,0,1-.088-.833.8.8,0,0,1,.814-.61c1.042-.023,2.085-.039,3.188-.057l1.4-.023,1.14.021c1.018.019,2.071.038,3.138.053a.828.828,0,0,1,.883.553.923.923,0,0,1-.235,1.082.678.678,0,0,1-.368.168c-1.051.066-2.006.1-2.92.1Z" transform="translate(-116.628 -620.081)" fill="#fff" />
                <path id="Path_383" data-name="Path 383" d="M-815.075-611.17c-1.6,0-3.225-.015-4.8-.03l-1.156-.011a3.44,3.44,0,0,1-.382-.034l-.137-.017a.745.745,0,0,1-.546-.487A.956.956,0,0,1-822-612.7a.846.846,0,0,1,.7-.377h.005c.568,0,1.188,0,1.951,0h2.933v-.068l.852.013c1.029.016,2.093.032,3.137.066a.95.95,0,0,1,.75.354.942.942,0,0,1,.136.811c-.016.064-.17.625-.651.642l-.731.029c-.654.027-1.33.056-1.989.056Z" transform="translate(-116.885 -387.092)" fill="#fff" />
                <path id="Path_384" data-name="Path 384" d="M-815.7-488.823c-1.5,0-3.026-.009-4.5-.018l-.523,0c-.143,0-.291-.012-.447-.024-.119-.009-.243-.019-.369-.024a.713.713,0,0,1-.593-.356,1.03,1.03,0,0,1-.128-.872c.207-.508.465-.583.714-.589,1.079-.025,2.177-.048,3.239-.07l.826-.018v.043h2.477c.723,0,1.28,0,1.806,0,.121,0,.247.011.38.022s.277.022.423.024a.728.728,0,0,1,.637.413,1.039,1.039,0,0,1-.012,1.013.772.772,0,0,1-.682.391l-.516.017c-.67.023-1.363.047-2.041.048Z" transform="translate(-116.759 -503.598)" fill="#fff" />
            </g>
        </g>
    </svg>

)

export const TradeAccount = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="83.78" height="83.78" viewBox="0 0 83.78 83.78">
        <g id="Group_353" data-name="Group 353" transform="translate(1 1)">
            <g id="Group_36" data-name="Group 36">
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="40.89" cy="40.89" r="40.89" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="2" />
                <path id="Path_385" data-name="Path 385" d="M94.006-1968.343c-3.307,0-5.832-.008-8.187-.025a6.648,6.648,0,0,1-2.386-.423,4.013,4.013,0,0,1-2.526-3.526,7.98,7.98,0,0,1-.038-.97v-2.985c0-5.373,0-10.928.01-16.392a7.138,7.138,0,0,1,.225-1.949,4.246,4.246,0,0,1,4.211-3.065c.742-.012,1.553-.018,2.551-.018q.814,0,1.628,0t1.631,0H92.6v-.446c0-.345,0-.681,0-1.012,0-.714,0-1.388,0-2.069a4.714,4.714,0,0,1,4.848-4.87c1.563,0,3.165,0,4.76,0,1.58,0,3.2,0,4.815,0a4.716,4.716,0,0,1,4.855,4.863c0,.682,0,1.358,0,2.073,0,.332,0,.669,0,1.015v.446H113.1l1.784,0,1.782,0c.865,0,1.579,0,2.247.007a4.358,4.358,0,0,1,4.7,4.622c.031,6.382.031,13.135,0,20.071a4.647,4.647,0,0,1-1.269,3.4,4.851,4.851,0,0,1-3.466,1.24c-2.075.007-4.324.01-7.078.01l-4.862,0-4.862,0h-.054l-3.987,0ZM88.2-1995.208c-1.052,0-1.911,0-2.706.009a1.938,1.938,0,0,0-2.171,2.179c-.009,6.528-.009,13.259,0,20.008a2.2,2.2,0,0,0,.571,1.651,2.26,2.26,0,0,0,1.661.555c5.529,0,11.132,0,16.654,0,5.554,0,11.183,0,16.729,0a2.257,2.257,0,0,0,1.66-.555,2.2,2.2,0,0,0,.57-1.652c.009-6.757.009-13.489,0-20.008a1.939,1.939,0,0,0-2.173-2.178c-.779-.006-1.61-.009-2.615-.009l-2.13,0-1.556,0a1.843,1.843,0,0,0-.284.028l-.136.019-.391.048v.394c0,.169,0,.335,0,.5,0,.352,0,.685,0,1.019-.021.876-.436,1.322-1.234,1.324h-.007c-.8,0-1.219-.44-1.241-1.307-.009-.348-.006-.705,0-1.051,0-.161,0-.322,0-.482v-.446H95.088v.446c0,.182,0,.359,0,.533,0,.368.007.716-.007,1.061a1.138,1.138,0,0,1-1.239,1.246h-.009a1.15,1.15,0,0,1-1.23-1.265c-.011-.344-.009-.694-.006-1.065,0-.179,0-.364,0-.556v-.446h-.446l-2.02,0Zm11.872-8.4c-1.127,0-2,0-2.824.008a1.9,1.9,0,0,0-2.115,1.738c-.046.863-.036,1.741-.026,2.59,0,.362.009.737.009,1.108v.446H109.4v-.446q0-.512,0-1.014c0-.767,0-1.491,0-2.231a2,2,0,0,0-2.173-2.191c-.471-.006-.981-.009-1.6-.009l-1.11,0-1.093,0h-3.342Z" transform="translate(-61.358 2025.106)" fill="#fff" />
            </g>
        </g>
    </svg>

)

export const StockLevel = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.925" height="9.38" viewBox="0 0 20.925 9.38">
        <g id="Group_322" data-name="Group 322" transform="translate(-481 -449.12)">
            <g id="Ellipse_20" data-name="Ellipse 20" transform="translate(481 449.12)" fill="#429853" stroke="#fafafa" strokeWidth="1">
                <circle cx="4.69" cy="4.69" r="4.69" stroke="none" />
                <circle cx="4.69" cy="4.69" r="4.19" fill="none" />
            </g>
            <g id="Ellipse_21" data-name="Ellipse 21" transform="translate(486.772 449.12)" fill="#429853" stroke="#fafafa" strokeWidth="1">
                <circle cx="4.69" cy="4.69" r="4.69" stroke="none" />
                <circle cx="4.69" cy="4.69" r="4.19" fill="none" />
            </g>
            <g id="Ellipse_22" data-name="Ellipse 22" transform="translate(492.545 449.12)" fill="#429853" stroke="#fafafa" strokeWidth="1">
                <circle cx="4.69" cy="4.69" r="4.69" stroke="none" />
                <circle cx="4.69" cy="4.69" r="4.19" fill="none" />
            </g>
        </g>
    </svg>

)

export const DownloadAssets = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.254" height="11.416" viewBox="0 0 9.254 11.416">
        <g id="Group_400" data-name="Group 400" transform="translate(0.383 8.272) rotate(-90)">
            <path id="Path_13" data-name="Path 13" d="M4.274,8.547,0,4.274,4.274,0" transform="translate(-0.602 -0.029)" fill="none" stroke="#a7a7a7" strokeWidth="1" />
            <line id="Line_7" data-name="Line 7" x2="8.599" transform="translate(-0.327 4.244)" fill="none" stroke="#a7a7a7" strokeWidth="1" />
            <line id="Line_81" data-name="Line 81" y2="8.722" transform="translate(-2.644 -0.128)" fill="none" stroke="#a7a7a7" strokeWidth="1" />
        </g>
    </svg>

)

export const AddToOrder = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.957" height="20.688" viewBox="0 0 25.957 20.688">
        <g id="Group_323" data-name="Group 323" transform="translate(252.045 617.595) rotate(90)">
            <rect id="Rectangle_30" data-name="Rectangle 30" width="19.188" height="19.188" transform="translate(-616.845 232.107)" fill="none" stroke="#fff" strokeWidth="1.5" />
            <path id="Path_372" data-name="Path 372" d="M-615.788,232.106a8.2,8.2,0,0,1,7.661-5.268,8.2,8.2,0,0,1,7.661,5.268" transform="translate(0.876 0)" fill="none" stroke="#fff" strokeWidth="1.5" />
        </g>
    </svg>

)

export const PlayIcon = () => (
    <svg id="Group_326" data-name="Group 326" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
        <g id="Group_280" data-name="Group 280" transform="translate(19.163 19.035) rotate(180)">
            <path id="Path_13" data-name="Path 13" d="M8.739,11.96,0,5.98,8.739,0Z" fill="none" stroke="#1f1f1f" strokeWidth="1" />
        </g>
        <g id="Ellipse_56" data-name="Ellipse 56" fill="none" stroke="#1f1f1f" strokeWidth="1">
            <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
            <circle cx="13.5" cy="13.5" r="13" fill="none" />
        </g>
    </svg>
)

export const PauseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
        <g id="Group_325" data-name="Group 325" transform="translate(0 -0.312)">
            <g id="Ellipse_56" data-name="Ellipse 56" transform="translate(0 0.312)" fill="none" stroke="#1f1f1f" strokeWidth="1">
                <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
                <circle cx="13.5" cy="13.5" r="13" fill="none" />
            </g>
            <g id="Group_280" data-name="Group 280" transform="translate(16.5 20.125) rotate(180)">
                <g id="Group_329" data-name="Group 329">
                    <path id="Path_13" data-name="Path 13" d="M0,12.625v0Z" transform="translate(6)" fill="#141414" stroke="#141414" strokeWidth="1" />
                    <path id="Path_375" data-name="Path 375" d="M0,12.625v0Z" fill="#141414" stroke="#141414" strokeWidth="1" />
                </g>
            </g>
        </g>
    </svg>

)

export const DownArrowBlack = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.74" height="16.289" viewBox="0 0 15.74 16.289">
        <g id="Group_387" data-name="Group 387" transform="translate(-4707.516 -25.921)">
            <path id="Path_388" data-name="Path 388" d="M7.163,14.326,0,7.163,7.163,0" transform="translate(4708.223 40.796) rotate(-90)" fill="none" stroke="#141414" strokeWidth="2" />
            <line id="Line_70" data-name="Line 70" x2="14.413" transform="translate(4715.385 40.334) rotate(-90)" fill="none" stroke="#141414" strokeWidth="2" />
        </g>
    </svg>

)

export const Hamburger = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="19.009" viewBox="0 0 28 19.009">
        <g id="Group_430" data-name="Group 430" transform="translate(-332 -14.995)">
            <line id="Line_76" data-name="Line 76" x1="28" transform="translate(332 33.005)" fill="none" stroke="#fff" strokeWidth="2" />
            <line id="Line_77" data-name="Line 77" x1="28" transform="translate(332 24.5)" fill="none" stroke="#fff" strokeWidth="2" />
            <line id="Line_78" data-name="Line 78" x1="28" transform="translate(332 15.995)" fill="none" stroke="#fff" strokeWidth="2" />
        </g>
    </svg>

)

export const CrossIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.414" height="27.414" viewBox="0 0 27.414 27.414">
        <g id="Group_431" data-name="Group 431" transform="translate(-331.793 -10.793)">
            <line id="Line_76" data-name="Line 76" x1="26" y2="26" transform="translate(332.5 11.5)" fill="none" stroke="#fff" strokeWidth="2" />
            <line id="Line_77" data-name="Line 77" x1="26" y1="26" transform="translate(332.5 11.5)" fill="none" stroke="#fff" strokeWidth="2" />
        </g>
    </svg>

)

export const Twitter = () => (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="40.167px" height="40.75px" viewBox="0 0 40.167 40.75" enableBackground="new 0 0 40.167 40.75">
        <g id="Group_268" transform="translate(851.002 4146.125)">
            <circle id="Ellipse_14" fill="none" stroke="#FFFFFF" cx="-830.961" cy="-4125.778" r="19.009" />
            <g id="Group_267" transform="translate(8.689 9.869)">
                <g id="Group_266">
                    <path id="Path_368" fill="#FFFFFF" d="M-827.47-4142.625c-0.848,0.369-1.742,0.614-2.658,0.728
				c0.966-0.571,1.688-1.479,2.029-2.549c-0.906,0.535-1.895,0.913-2.926,1.117c-0.873-0.932-2.094-1.459-3.37-1.459
				c-2.546,0.001-4.61,2.065-4.609,4.611c0,0,0,0,0,0.001c-0.004,0.354,0.032,0.707,0.107,1.053
				c-3.711-0.183-7.168-1.938-9.507-4.825c-0.413,0.708-0.631,1.512-0.632,2.331c0.001,1.54,0.771,2.978,2.05,3.833
				c-0.73-0.02-1.445-0.214-2.084-0.568v0.051c0.002,2.195,1.545,4.088,3.695,4.532c-0.395,0.104-0.801,0.155-1.209,0.151
				c-0.293,0.005-0.586-0.021-0.874-0.078c0.611,1.876,2.339,3.163,4.311,3.212c-1.633,1.277-3.647,1.97-5.72,1.968
				c-0.367,0.002-0.735-0.019-1.1-0.062c2.109,1.358,4.567,2.076,7.077,2.068c7.199,0.05,13.077-5.747,13.127-12.947
				c0-0.059,0-0.118,0-0.178c0-0.2-0.008-0.4-0.016-0.601C-828.871-4140.885-828.089-4141.695-827.47-4142.625z"/>
                </g>
            </g>
        </g>
    </svg>

)

export const Insta = () => (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="42.52px" height="42.52px" viewBox="0 0 42.52 42.52" enableBackground="new 0 0 42.52 42.52" >
        <g id="Group_265" transform="translate(776.72 4146.125)">
            <circle id="Ellipse_12" fill="none" stroke="#FFFFFF" cx="-755.592" cy="-4125.301" r="19.009" />
            <g id="Group_264" transform="translate(7.759 7.759)">
                <g id="Group_259">
                    <g id="Group_258">
                        <path id="Path_366" fill="#FFFFFF" d="M-759.131-4144.31h-8.438c-3.883,0.001-7.031,3.148-7.031,7.031v8.438
					c0.001,3.882,3.148,7.029,7.031,7.029h8.438c3.883,0,7.029-3.147,7.031-7.029v-8.438
					C-752.102-4141.162-755.248-4144.309-759.131-4144.31z M-754.209-4128.841c-0.004,2.716-2.205,4.917-4.922,4.921h-8.438
					c-2.716-0.004-4.918-2.205-4.922-4.921v-8.438c0.004-2.717,2.206-4.918,4.922-4.922h8.438c2.717,0.003,4.918,2.205,4.922,4.922
					V-4128.841z"/>
                    </g>
                </g>
                <g id="Group_261" transform="translate(5.625 5.625)">
                    <g id="Group_260">
                        <path id="Path_367" fill="#FFFFFF" d="M-768.975-4144.31c-3.106,0-5.625,2.519-5.625,5.624c0,3.106,2.519,5.625,5.625,5.625
					c3.106,0,5.624-2.519,5.624-5.625C-763.352-4141.792-765.869-4144.31-768.975-4144.31L-768.975-4144.31z M-768.975-4135.17
					c-1.94-0.003-3.513-1.575-3.515-3.516c0.001-1.94,1.575-3.513,3.515-3.515c1.94,0.002,3.512,1.575,3.514,3.515
					C-765.463-4136.746-767.035-4135.172-768.975-4135.17L-768.975-4135.17z"/>
                    </g>
                </g>
                <g id="Group_263" transform="translate(16.546 4.453)">
                    <g id="Group_262">
                        <circle id="Ellipse_13" fill="#FFFFFF" cx="-773.851" cy="-4143.561" r="0.749" />
                    </g>
                </g>
            </g>
        </g>
    </svg>


)

export const Youtube = () => (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="39.685px" height="39.685px" viewBox="0 0 39.685 39.685" enableBackground="new 0 0 39.685 39.685">
        <g id="Group_271" transform="translate(924.421 4146.125)">
            <circle id="Ellipse_15" fill="none" stroke="#FFFFFF" cx="-904.251" cy="-4126.283" r="19.009" />
            <g id="Group_270" transform="translate(7.202 10.892)">
                <g id="Group_269">
                    <path id="Path_369" fill="#FFFFFF" d="M-900.651-4143.727c-0.641-1.14-1.336-1.35-2.75-1.43c-1.414-0.101-4.97-0.136-8.05-0.136
				s-6.642,0.04-8.056,0.135c-1.412,0.095-2.107,0.289-2.754,1.431s-1,3.101-1,6.547v0.013c0,3.435,0.34,5.409,1,6.535
				s1.343,1.347,2.754,1.442c1.41,0.096,4.971,0.13,8.056,0.13s6.636-0.047,8.052-0.13s2.11-0.3,2.75-1.442
				c0.666-1.125,1-3.1,1-6.533v-0.012C-899.647-4140.629-899.985-4142.588-900.651-4143.727z M-914.405-4132.748v-8.855l7.379,4.427
				L-914.405-4132.748z"/>
                </g>
            </g>
        </g>
    </svg>

)

export const Wifi = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="39.685px" height="39.685px" viewBox="0 0 39.685 39.685" enableBackground="new 0 0 39.685 39.685" >
        <g id="Group_274" transform="translate(998.703 4146.125)">
            <circle id="Ellipse_16" fill="none" stroke="#FFFFFF" cx="-978.249" cy="-4126.718" r="19.009" />
            <g id="Group_273" transform="translate(5.811 10.851)">
                <g id="Group_272">
                    <path id="Path_370" fill="#FFFFFF" d="M-975.73-4129.14c-0.222,0.317-0.585,0.507-0.973,0.507
				c-0.244,0.001-0.482-0.074-0.682-0.216c-4.455-3.133-11.867-3.307-16.871-2.141c-0.639,0.148-1.277-0.248-1.426-0.887
				c-0.148-0.64,0.25-1.279,0.89-1.428c5.5-1.274,13.724-1.041,18.771,2.511C-975.485-4130.417-975.355-4129.677-975.73-4129.14z
				 M-973.411-4133.775c-0.224,0.311-0.582,0.493-0.964,0.493c-0.252,0.002-0.496-0.077-0.7-0.224
				c-5.167-3.708-11.517-4.63-19.987-2.9c-0.642,0.123-1.264-0.288-1.4-0.927c-0.131-0.642,0.283-1.269,0.925-1.4
				c9.165-1.87,16.104-0.824,21.854,3.301C-973.156-4135.046-973.035-4134.309-973.411-4133.775z M-971.069-4140
				c-0.22,0.326-0.589,0.521-0.981,0.519c-0.238,0.002-0.471-0.068-0.668-0.199c-1.939-1.265-4.074-2.205-6.316-2.783
				c-5.488-1.415-11.269-1.207-16.641,0.6c-0.619,0.217-1.297-0.108-1.516-0.727c-0.219-0.619,0.105-1.298,0.725-1.516v-0.001
				c7.015-2.478,17.879-2.426,25.086,2.461C-970.843-4141.276-970.704-4140.542-971.069-4140L-971.069-4140z"/>
                </g>
            </g>
        </g>
    </svg>

)

export const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>
)

export const Minus = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M6 19h12v2H6z" /></svg>
)

export const Add = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" /></svg>
)

export const DownArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="none" className="a" d="M0,0H20V20H0Z"/><path fill="none" stroke="#080808" stroke-linecap="round" stroke-linejoin= "round" stroke-width="1.5px"  className="b" d="M6,8l4,4,4-4"/></svg>
)
