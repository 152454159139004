import { Typography } from 'antd';
import { MainLogo } from '../../assets/images';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <MainLogo />
            <div className="bottom">
                <Typography.Text className="loader-text">LOADING...</Typography.Text>
            </div>
        </div>
    )
}

export default LoadingScreen;