import { combineReducers } from 'redux';
import { commonReducer } from './common/commonReducer';
import { labelsReducer } from './labels/labelsReducer';
import { productsReducer } from './products/productsReducer';
import {formatReducer} from './formatFilter/formatReducer'
import { Constants } from './common/commonTypes';
import { userReducer } from './users/userReducer';

const appReducer = combineReducers({
    common: commonReducer,
    user: userReducer,
    labels: labelsReducer,
    products: productsReducer,
    formats : formatReducer,

})

const rootReducer: any = (state: any, action: any) => {
    if (action.type === Constants.RESET_STORE) {
        state = undefined;
    }
    return appReducer(state, action)
}

export default rootReducer;