import { FormatsMdl, GenericMdl } from "../../Modal/formats";
import {  Constants, FormatActions, IFormatsState } from "./formatTypes";
const init: IFormatsState = {
    formats: [] as FormatsMdl[],
    generics : []  as GenericMdl[]
}
export const formatReducer = (state: IFormatsState = init, action: FormatActions) => {
    switch (action.type) {
        case Constants.SET_FORMATFILTER:
            return {
                ...state,
                formatList: action.payload.formats,
            }
            case Constants.SET_GENERICSFILTER:
                return {
                    ...state,
                    generics: action.payload.generics,
                }
        default:
            return state;
    }
}