import { LoginObject, User, GlobalErrorObject } from "../../Modal/User";
import { UserActions, Constants, IUserState } from "./userTypes";

const init: IUserState = {
  userSession: undefined as any | LoginObject,
  usersList: [] as User[],
  globalErrorObject: undefined as any | GlobalErrorObject,
};

export const userReducer = (state: IUserState = init, action: UserActions) => {
  switch (action.type) {
    case Constants.SET_LOGIN_STATUS:
      return {
        ...state,
        isLogin: action.payload.isLogin,
      };
    case Constants.SET_USER_SESSION:
      return {
        ...state,
        userSession: action.payload.loginRes,
      };
    case Constants.SET_USERSLIST:
      return {
        ...state,
        usersList: action.payload.usersList,
      };
    case Constants.SET_USERSERRORRES:
      return {
        ...state,
        globalErrorObject: action.payload.globalerrorRes,
      };
    default:
      return state;
  }
};
