import { CommonActions, Constants, ICommonState } from "./commonTypes";
import {CustomerData} from "../../Modal/Labels"
const init: ICommonState = {
    loading: false,
    customerData : [] as CustomerData
}

export const commonReducer = (state: ICommonState = init, action: CommonActions) => {
    switch (action.type) {
        case Constants.SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
            case Constants.SET_CUSTOMERDATA:
                return{
                    ...state,
                    customerData : action.payload.customerData
                }
        default:
            return state;
    }
}