import { ActionType } from "typesafe-actions";
import * as actions from "./productsActions";
import { ProductsMdl } from "../../Modal/Products";

export type ProductsActions = ActionType<typeof actions>;

export interface IProductsState {
  products: ProductsMdl[];
  product: ProductsMdl[];
  Relproducts: ProductsMdl[];
  cartProductOrder: ProductsMdl[];
  AddedCartProduct: any;
  CartData: any;
  CartOrderData: any;
  searchData: any;
  previousLocation: any
  secondaryImageUrl:any,
  productReleseasValue :any,
}

export enum Constants {
  SET_PRODUCTS = "SET_PRODUCTS",
  SET_PRODUCT_COUNT = "SET_PRODUCT_COUNT",
  SET_PRODUCT = "SET_PRODUCT",
  SET_REL_PRODUCTS = "SET_REL_PRODUCTS",
  SET_CART_PRODUCT_ORDER = "SET_CART_PRODUCT_ORDER",
  SET_ADDED_CART_PRODUCT = "SET_ADDED_CART_PRODUCT",
  SET_CART_DATA = "SET_CART_DATA",
  CART_ORDER_DATA = "CART_ORDER_DATA",
  SET_SEARCH_DATA = "SET_SEARCH_DATA",
  PREVIOUS_LOCATION_URL = "PREVIOUS_LOCATION_URL",
  SECONDARY_iMAGE_URL = "SECONDARY_iMAGE_URL",
  SET_PRODUCT_RELEASES_ENUM_VALUE ="SET_PRODUCT_RELEASES_ENUM_VALUE"
}
