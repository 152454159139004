import { ActionType } from 'typesafe-actions';
import * as actions from '../labels/labelsActions';
import { LabelsMdl } from '../../Modal/Labels';


export type LabelsActions = ActionType<typeof actions>;

export interface ILabelsState {
  labels: LabelsMdl[]
}

export enum Constants {
  SET_LABELES = 'SET_LABELES'
}