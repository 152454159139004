import {
  ProductsActions,
  Constants,
  IProductsState,
} from "../products/productsTypes";
import { ProductsMdl } from "../../Modal/Products";
const init: IProductsState = {
  products: [] as ProductsMdl[],
  product: [] as ProductsMdl[],
  Relproducts: [] as ProductsMdl[],
  cartProductOrder: [] as ProductsMdl[],
  AddedCartProduct: [] as any,
  CartData: [] as any,
  CartOrderData: [] as any,
  searchData: [] as any,
  previousLocation: [] as any,
  secondaryImageUrl : [] as any,
  productReleseasValue:[] as any
};

export const productsReducer = (
  state: IProductsState = init,
  action: ProductsActions
) => {
  switch (action.type) {
    case Constants.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload.loadMore
          ? [...state.products, ...action.payload.products]
          : [...action.payload.products],
      };
    case Constants.SET_PRODUCT_COUNT:
      return {
        ...state,
        productCount: action.payload.count,
      };
    case Constants.SET_PRODUCT:
      return {
        ...state,
        product: action.payload.product,
      };
    case Constants.SET_REL_PRODUCTS:
      return {
        ...state,
        Relproducts: action.payload.Relproducts,
      };
    case Constants.SET_CART_PRODUCT_ORDER:
      return {
        ...state,
        CartProductOrder: action.payload.CartProductOrder,
      };

    case Constants.SET_CART_DATA:
      return {
        ...state,
        AddedCartProduct: action.payload.AddedCartProduct,
      };
    case Constants.SET_ADDED_CART_PRODUCT:
      return {
        ...state,
        CartData: action.payload.CartData,
      };
    case Constants.CART_ORDER_DATA:
      return {
        ...state,
        CartOrderData: action.payload.CartOrderData,
      };
    case Constants.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload.searchData,
      };
      case Constants.PREVIOUS_LOCATION_URL:
        return {
          ...state,
          previousLocation: action.payload.previousLocation,
        };
      case Constants.SECONDARY_iMAGE_URL:
      return {
        ...state,
        secondaryImageUrl: action.payload.secondaryImageUrl,
      };
      case Constants.SET_PRODUCT_RELEASES_ENUM_VALUE:
        return {
          ...state,
          productReleseasValue: action.payload.productReleseasValue,
        };
    default:
      return state;
  }
};
