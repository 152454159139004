import { ActionType } from 'typesafe-actions';
import { LoginObject, User, GlobalErrorObject } from '../../Modal/User';
import * as actions from './userActions';

export type UserActions = ActionType<typeof actions>;

export interface IUserState {
    userSession: LoginObject,
    usersList: User[],
    globalErrorObject: GlobalErrorObject
}

export enum Constants {
    SET_LOGIN_STATUS = 'SET_LOGIN_STATUS',
    SET_USER_SESSION = 'SET_USER_SESSION',
    SET_USERSLIST = 'SET_USERSLIST',
    SET_USERSERRORRES = 'SET_USERSERRORRES'
}