import { ActionType } from "typesafe-actions";
import { FormatsMdl, GenericMdl } from "../../Modal/formats";
import * as actions from '../formatFilter/formatActions';

export type FormatActions = ActionType<typeof actions>;

export interface IFormatsState {
  formats: FormatsMdl[],
  generics: GenericMdl[]
}


export enum Constants {
  SET_FORMATFILTER = 'SET_FORMATFILTERS',
  SET_GENERICSFILTER = 'SET_GENERICFILTER'
}