import { LabelsActions, Constants, ILabelsState } from "../labels/labelsTypes";
import { LabelsMdl } from "../../Modal/Labels";
const init: ILabelsState = {
    labels: [] as LabelsMdl[],
}

export const labelsReducer = (state: ILabelsState = init, action: LabelsActions) => {
    switch (action.type) {
        case Constants.SET_LABELES:
            return {
                ...state,
                labels: action.payload.labels,
            }
        default:
            return state;
    }
}